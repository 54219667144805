import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import HomePage from "./pages/HomePage";
// import SigninPage from "./pages/SigninPage";
// import SignupPage from "./pages/SignupPage";
import BrowsePage from "./pages/BrowsePage";
import BrowsePageUk from "./pages/BrowsePageUk";
import IntroPage from "./pages/IntroPage";
import IntroPageUk from "./pages/IntroPageUk";
import CreditsPage from "./pages/CreditsPage";
import CreditsPageUk from "./pages/CreditsPageUk";
import App from "./App";
import playlist from './data/playlist.json';
import playlisten from './data/playlisten.json';


const YOUTUBE_PLAYLIST_ITEMS_API = 'https://www.googleapis.com/youtube/v3/playlistItems?key=AIzaSyCmygbZmRVuE_MeGZ5kwNXRNN3RdGIEXXc&maxResults=10&playlistId=';
export async function getServerSideProps(id) {
  const res = await fetch(YOUTUBE_PLAYLIST_ITEMS_API + id + '&part=snippet,contentDetails');
  const data = await res.json();
  return {
    props: {
      data
    }
  };
}

class MainRouter extends React.Component {
  constructor(props) {
    super(props);
    
    // playlist.forEach(p => {
    //   getServerSideProps(p.id).then(data => {
    //     if (data && data.props && data.props.data && data.props.data.items && data.props.data.items.length > 0) {
    //       p.items = data.props.data.items;
    //     }
    //     p.items.forEach(item => {
    //       item.category = p.title;
    //     })
    //   })
    // })
    // playlisten.forEach(p => {
    //   getServerSideProps(p.id).then(data => {
    //     if (data && data.props && data.props.data && data.props.data.items && data.props.data.items.length > 0) {
    //       p.items = data.props.data.items;
    //     }
    //     p.items.forEach(item => {
    //       item.category = p.title;
    //     })
    //   })
    // })
    this.state = {
      playlist: playlist,
      playlisten: playlisten
    };
  }

  render() {
    // console.log(this.state)
    return this.Tolkienfix();
  }

  Tolkienfix() {
    return (
      <Router>
        <Switch>
        <Route exact path="/">
          <BrowsePage dataParentToChild = {this.state.playlist}/>
        </Route>
        <Route exact path="/en">
          <BrowsePageUk dataParentToChild = {this.state.playlisten}/>
        </Route>
        <Route path="/intro">
          <IntroPage dataParentToChild = {this.state.playlist}/>
        </Route>
        <Route path="/en/intro">
          <IntroPageUk dataParentToChild = {this.state.playlisten}/>
        </Route>
        <Route path="/crediti">
          <CreditsPage />
        </Route>
        <Route path="/en/credits">
          <CreditsPageUk />
        </Route>
        <Route path="/watch">
          <App />
        </Route>
        </Switch>
      </Router>
    );
  }

}



export default MainRouter;