import React, { useState } from "react";
// import useContent from "../custom-hooks/useContent";
import HeaderWrapper from "../components/Header/HeaderWrapper";
import NavBar from "../components/Header/NavBar";
import Logo from "../components/Header/Logo";
import FeatureWrapper from "../components/Header/FeatureWrapper";
import FeatureTitle from "../components/Header/FeatureTitle";
import FeatureSubTitle from "../components/Header/FeatureSubTitle";
import HeaderLink from "../components/Header/HeaderLink";
import FooterCompound from "../compounds/FooterCompound";
import LocalizedStrings from 'react-localization';



const YOUTUBE_PLAYLIST_ITEMS_API = 'https://www.googleapis.com/youtube/v3/playlistItems?key=AIzaSyCmygbZmRVuE_MeGZ5kwNXRNN3RdGIEXXc&playlistId=';
export async function getServerSideProps(id) {
  const res = await fetch(YOUTUBE_PLAYLIST_ITEMS_API + id + '&part=snippet,contentDetails');
  const data = await res.json();
  return {
    props: {
      data
    }
  };
}

function CreditsPageUk() {
  let strings = new LocalizedStrings({
    en:{
      menu_a: "Home",
      menu_b: "Credits",
      how: "How works the experience?"
    },
    it: {
      menu_a: "Home",
      menu_b: "Credits",
      how: "Come posso visitare la mostra?"
  
    }
   });
  strings.setLanguage('it');
  
  const [category] = useState("content");

  return (
    <>
      <HeaderWrapper className="header-wrapper-browse">
        <NavBar className="navbar-browse">
          <Logo href="/en"/>
          <HeaderLink
            className={
              category === "content" ? "header-link-bold" : "header-link"
            }
            href="/en"
          >
            { strings.menu_a }
          </HeaderLink>
          <HeaderLink
            className={
              category === "credits" ? "header-link-bold" : "header-link"
            }
            href="/en/credits"
          >
            { strings.menu_b }
          </HeaderLink>
          <HeaderLink
            className={
              category === "credits" ? "header-link-bold " : "header-link "
            }
            href="/"
          >
            ITA
          </HeaderLink>
        </NavBar>
        <FeatureWrapper>
          <FeatureTitle className="feature-title-browse">
            The Tree Of Tales
          </FeatureTitle>
          <FeatureSubTitle className="feature-subtitle-browse">
            Tolkien Exhibition
          </FeatureSubTitle>
          <div >
            <a className="intro-button" href="/intro" >
              Mostra Virtuale
            </a>
            <a className="intro-button" href="/#PLBQKCyMz1HZkN12jAUefazaOubdlAKpYZ">
              Video
            </a>
          </div>
        </FeatureWrapper>
      </HeaderWrapper>
      <div className="credits">
        <h3>CREDITS</h3>
        <p>
            CURATORS Giuseppe Pezzini, Emmanuele Riu<br />
            EDITORIAL BOARD Roger Sylvester, Michela Young, Oronzo Cilli<br />
            ART DIRECTOR Paolo Morandi<br />
            PRODUCTION MANAGER Collarino & Morando Communicazione<br />
            PROJECT MANAGER Matteo Bruschi<br />
        </p>
        <p>
            VR PROJECT (designed by API S.r.l)<br />
            PROJECT MANAGER E LEVEL DESIGNER: Biancamaria Mori <br />
            PROGRAMMERS Carlo Gioventù, Erik Castiglione<br />
            3D GENERALIST Andrea Guerra<br />
            LEVEL DESIGNER Sara Sorano<br />
        </p>
        <p>
            WEB INTEGRATION Lorenzo Speranza<br />
            STREAMING and DISTRIBUTION Pureweb<br />
        </p>
        <p>
            HOTSPOTS (video, audio, e panels):<br />
            POSTPRODUCTION Simone Cerrato, Marta Riu, Francesco Presutti, Roberta Primo<br />
            GRAPHICS ANIMATIONS  2D Fausto Collarino<br />
            SOUNDTRACK Martino Vacca e Alessandro Artico<br />
            AFTER EFFECTS Lukas Danihel<br />
        </p>
        <p>
            ITALIAN VOICES<br />Fabio Ardu, Giorgio Bonino, Massimo Corvo, Elisa Gandolfi, Samia Kassir, Paolo Malgioglio, Davide Perino, Giorgio Sanna, Mario Zucca
        </p>
        <p>
            ENGLISH VOICES<br />
            John Burke. Simon D’Aquino, Anthony Errington, Washim Gafoor, David Nagel, Siobhan O’Shea, Cormac Rowe, Richard Solomon, Daniel Sylvester, Roger Sylvester, Marco Young, Michela Young
        </p> 
        <p>
            COPY and RESEARCH<br />
            Giuseppe Pezzini, Emmanuele Riu, Michela Young, Roger Sylvester, Matteo Bruschi, Oronzo Cilli, Paolo Morandi, Guglielmo Spirito, Hamish Williams.
            <br />First part (Life): Anna Arigossi, Davide Corti, Chiara Di Santis, Anthony Errington, Enrico Manzon, Chiara Ferrario, Trevor Ferros, Abigail Parisaca Vargas, Laura Staccoli, Paolo Tripoli, Lucia Vannini, Alessandra Vittiglio, Benedetta Zeni.
            <br />Seconda parte (Creativity): Anine Englund, Clinton Collister, Trevor Hart, Rebekah Lamb, Siobhan Maloney, Sarah Moffit, Austin Moyers, James Smoker, Michael Thames, Paul Treschow, Brendan Wolfe, Judith Wolfe.
            <br />Third part (LOTR): Luca Arrighini, Giacomo Astrua, Anna Bardazzi, Andrea Budelli, Edoardo Bernardi, Stefano Campisi, Federico Coscia, Marta Folcarelli, Davide Michelis, Simone Michelis, Giovanni Minghetti, Chiara Nejrotti, Pier Luigi Pezzini, Stefano Pistillo, Margherita Scalon, Ivano Sassanelli.
        </p>
        <p>
            Video Ainulindalë<br />
            VIDEOMAKER Mario Pietro Brioschi<br />
            ARTWORK Giovanni Bonapace, Fausto Collarino<br />
            MUSICA Nicolò Facciotto, Federico Mecozzi, Ivan Tiraferri<br />
            VIOLONCELLO E CONTRABBASSO Anselmo Pelliccioni<br />
            TROMBE Gioele Umberti Foppa<br />
            OBOE Ilaria De Maximy<br />
            CORNO Massimo Mondaini<br />
            FLAUTO Ilaria Moriconi<br />
            VOCE: Giorgio Sanna (Italiano), Anthony Errington (Inglese)<br />
        </p>
      </div>
        
      <FooterCompound />
    </>
  );
}

export default CreditsPageUk;
